import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import InputMask from "react-input-mask"
import {
  Flex,
  Text,
  FormControl,
  FormLabel,
  Input,
  Button,
  VStack,
  Image,
  Divider,
  HStack,
  useDisclosure,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Modal,
  useToast,
  RadioGroup,
  Stack,
  Radio,
} from "@chakra-ui/react"
import Layout from "../../../components/layout/Layout/Layout"
import { UserService } from "../../../services"
import { BankAccountService } from "../../../services/csg/BankAccount"
import { BankAccount } from "../../../entities"
import Formatter from "../../../util/formatter"
import Loader from "../../../components/Loader/Loader"
import { BalanceReturn } from "../../../entities/csg/BalanceReturn"
import { BalanceReturnService } from "../../../services/csg/BalanceReturnService"
import UserStorage from "../../../util/userStorage"
import { ValidationInput } from "../../../util"

export default function RedeemBalance() {
  const navigate = useNavigate()
  const toast = useToast()

  const [form, setForm] = useState({
    amount: "",
    tax: 0,
    total: 0,
    type: "",
    key: "",
  })

  const [bankAccount, setBankAccount] = useState<BankAccount>(new BankAccount())

  const [isLoading, setIsLoading] = useState(false)

  const [mask, setMask] = useState("")
  const [placeHolder, setPlaceHolder] = useState("")

  const { isOpen, onOpen, onClose } = useDisclosure()

  useEffect(() => {
    UserService.isValidUser().then((isValid) => {
      if (!isValid) {
        UserStorage.logout()
        navigate("/login")
        return
      }
      loadBankAccount()
    })
    // eslint-disable-next-line
  }, [])

  const changeAmount = (newAmount: string) => {
    let amount = parseFloat(newAmount || "0")
    console.log(amount)
    console.log(newAmount, amount * 100, bankAccount.balance)
    if (amount * 100 > bankAccount.balance) {
      amount = bankAccount.balance
      setForm({ ...form, amount: (bankAccount.balance / 100).toString() })
      toast({
        title: `Você pode regatar até ${Formatter.formatCurrency(
          bankAccount.balance
        )}`,
      })
      return
    }

    const tax = amount * 0.0389 + 0.05
    const total = amount - tax
    setForm({ ...form, amount: newAmount, tax, total })
  }

  const loadBankAccount = () => {
    console.log("teste")
    setIsLoading(true)
    BankAccountService.getByUser()
      .then((bankAccount) => setBankAccount(bankAccount))
      .finally(() => setIsLoading(false))
  }

  const openModal = () => {
    const amount = parseFloat(form.amount || "0")
    if (amount <= 0) {
      toast({
        title: "Digite o valor a ser resgatado",
        status: "error",
      })
      return
    }

    if (amount * 100 > bankAccount.balance) {
      toast({
        title: `O valor do resgate deve ser até ${Formatter.formatCurrency(
          bankAccount.balance
        )}.`,
        status: "error",
      })
      return
    }

    if (form.type === "") {
      toast({
        title: "Escolhe o tipo de chave Pix.",
        status: "error",
        duration: 2000,
      })
      return
    }

    if (form.type === "phone" && form.key.length !== 15) {
      toast({
        title: "Digite um telefone válido.",
        status: "error",
        duration: 2000,
      })
      return
    }

    if (form.type === "cpf" && form.key.length !== 14) {
      toast({
        title: "Digite um CPF válido.",
        status: "error",
        duration: 2000,
      })
      return
    }

    if (form.type === "cnpj") {
      if (form.key.length < 18) {
        toast({
          title: "Digite o CNPJ.",
          status: "error",
          duration: 2000,
        })
        return
      } else {
        if (!ValidationInput.isValidCNPJ(form.key)) {
          toast({
            title: "O CNPJ informado é inválido.",
            status: "error",
            duration: 2000,
          })
          return
        }
      }
    }

    if (form.type === "email" && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(form.key)) {
      toast({
        title: "Digite um e-mail válido.",
        status: "error",
        duration: 2000,
      })
      return
    }

    if (form.key.length === 0) {
      toast({
        title: "Digite uma chave aleatória.",
        status: "error",
        duration: 2000,
      })
      return
    }

    onOpen()
  }

  const save = () => {
    const amount = Math.ceil(parseFloat(form.amount) * 100)
    const balanceReturn = new BalanceReturn({
      amount,
      type: form.type,
      key: form.key,
    })

    onClose()

    setIsLoading(true)
    BalanceReturnService.create(balanceReturn)
      .then((newBalancereturn) => {
        toast({
          title: "Resgate solicitado com sucesso.",
          status: "success",
        })
        navigate("/perfil")
      })
      .finally(() => setIsLoading(false))
  }

  return (
    <Layout
      internal={true}
      menuSelected={"profile"}
      header={{
        title: "Resgatar saldo",
        backTo: "/perfil",
      }}
      valign="space-between"
    >
      {isLoading && <Loader />}
      <Flex
        mt="1rem"
        w="100%"
        maxWidth="515px"
        minH="500px"
        direction="column"
        gap="1rem"
        fontFamily="Raleway"
      >
        <Flex w="full" direction="column">
          <Flex mb="1rem">
            <Text color="#2D3748" fontSize="1rem" fontWeight="700">
              Quanto você quer resgatar do seu saldo?
            </Text>
          </Flex>
          <Flex mb="1rem" direction="column">
            <FormControl
              isRequired
              display="flex"
              justifyContent="space-between"
              gap="0.25rem"
            >
              <Input
                borderRadius="0.375rem"
                variant="outline"
                bg="#fff"
                size="md"
                type="number"
                value={form.amount}
                onChange={(event) => changeAmount(event.target.value)}
                placeholder="R$ 0,00"
              />
              <Button
                size="md"
                variant="solid"
                bg="#E2E8F0"
                fontSize="0.875rem"
                fontWeight="600"
                color="#1A202C"
                onClick={() =>
                  changeAmount((bankAccount.balance / 100).toString())
                }
              >
                Tudo
              </Button>
            </FormControl>
            <Text
              mt="0.25rem"
              fontSize="0.750rem"
              fontWeight="400"
              color="#8E99AB"
              alignSelf="flex-start"
            >
              Saldo após o resgate:{" "}
              {Formatter.formatCurrency(
                bankAccount.balance - parseFloat(form.amount || "0") * 100
              )}
            </Text>
          </Flex>
          <Divider orientation="horizontal" mb="0.35rem" />
          <Flex px="0.5rem" alignItems="center" justifyContent="space-between">
            <Text fontSize="0.750rem" fontWeight="400" color="#2D3748">
              Seu saldo atual
            </Text>
            <Text fontSize="0.750rem" fontWeight="500" color="#171923">
              R$ {parseFloat(form.amount || "0").toFixed(2)}
            </Text>
          </Flex>
          <Divider orientation="horizontal" my="0.35rem" />
          <Flex px="0.5rem" alignItems="center" justifyContent="space-between">
            <Text fontSize="0.750rem" fontWeight="400" color="#2D3748">
              Taxas e impostos
            </Text>
            <Text fontSize="0.750rem" fontWeight="500" color="#171923">
              - R$ {form.tax.toFixed(2)}
            </Text>
          </Flex>
          <Divider orientation="horizontal" my="0.35rem" />
          <Flex px="0.5rem" alignItems="center" justifyContent="space-between">
            <Text fontSize="0.750rem" fontWeight="400" color="#2D3748">
              Total do resgate
            </Text>
            <Text fontSize="0.750rem" fontWeight="500" color="#171923">
              R$ {form.total.toFixed(2)}
            </Text>
          </Flex>
          <Divider orientation="horizontal" mt="0.35rem" />
          <HStack
            mt="1rem"
            bg="#F7F8F9"
            p="0.625rem 2rem 0.625rem 0.75rem"
            borderRadius="0.5rem"
            display="flex"
            gap="0.625rem"
            alignItems="center"
          >
            <Image
              src="/images/card-icon/InfoGray.svg"
              alt="Ícone de informação"
            />
            <Text color="#4A5568" fontSize="0.75rem" fontWeight="500">
              Para resgatar o saldo, incidem 3,89% sobre o valor resgatado e uma
              taxa fixa de R$ 0,05 por operação. O prazo para o resgate do saldo
              será de 33 dias a partir da data da solicitação.
            </Text>
          </HStack>
          <VStack w="full" mt="2rem">
            <Text
              fontSize="1rem"
              fontWeight="700"
              color="#2D3748"
              alignSelf="flex-start"
            >
              Transferência por Pix
            </Text>
            <FormControl isRequired>
              <RadioGroup
                onChange={(value) => {
                  if (value === "phone") {
                    setMask("(99) 99999-9999")
                    setPlaceHolder("(99) 99999-9999")
                  } else if (value === "cpf") {
                    setMask("999.999.999-99")
                    setPlaceHolder("999.999.999-99")
                  } else if (value === "cnpj") {
                    setMask("99.999.999/9999-99")
                    setPlaceHolder("99.999.999/9999-99")
                  } else if (value === "email") {
                    setMask("")
                    setPlaceHolder("email@provedor.com")
                  } else {
                    setMask("")
                    setPlaceHolder("Chave aleatória")
                  }

                  setForm({ ...form, type: value })
                }}
              >
                <Stack>
                  <Radio value="phone">Celular</Radio>
                  <Radio value="email">E-mail</Radio>
                  <Radio value="cpf">CPF</Radio>
                  <Radio value="cnpj">CNPJ</Radio>
                  <Radio value="random">Chave Aleatória</Radio>
                </Stack>
              </RadioGroup>
            </FormControl>
            <FormControl isRequired w="100%">
              <FormLabel color="#171923" fontSize="0.875rem" fontWeight="500">
                Chave
              </FormLabel>
              <Input
                as={InputMask}
                mask={mask}
                maskChar={null}
                variant="outline"
                bg="#fff"
                size="lg"
                type="text"
                value={form.key}
                onChange={(event) =>
                  setForm({ ...form, key: event.target.value })
                }
                placeholder={placeHolder}
              />
            </FormControl>
          </VStack>
          <Flex mt="2rem">
            <Button
              onClick={() => openModal()}
              w="100%"
              size="lg"
              fontSize="1rem"
              variant="solid"
              bg="#00335B"
              color="#fff"
              _hover={{ bg: "#003f70", transition: "0.3s" }}
            >
              Resgatar saldo
            </Button>
            <Modal isOpen={isOpen} onClose={onClose}>
              <ModalOverlay />
              <ModalContent w="100%" maxWidth="300px" fontFamily="Raleway">
                <ModalHeader></ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <Text
                    mb="0.375rem"
                    color="#2D3748"
                    fontSize="1.25rem"
                    fontWeight="700"
                  >
                    Resgatar saldo?
                  </Text>
                  <Text color="#4A5568" fontSize="0.875rem" fontWeight="500">
                    Você solicitou o resgate do saldo.
                  </Text>
                  <Flex my="1rem" w="100%" gap="2.88rem">
                    <Flex direction="column">
                      <Text
                        color="#2D3748"
                        fontSize="0.750rem"
                        fontWeight="500"
                      >
                        Saldo solicitado
                      </Text>
                      <Text
                        color="#2D3748"
                        fontSize="0.875rem"
                        fontWeight="700"
                      >
                        R$ {parseFloat(form.amount || "0").toFixed(2)}
                      </Text>
                    </Flex>
                    <Flex direction="column">
                      <Text
                        color="#2D3748"
                        fontSize="0.750rem"
                        fontWeight="500"
                      >
                        Total do resgate
                      </Text>
                      <Text
                        color="#2D3748"
                        fontSize="0.875rem"
                        fontWeight="700"
                      >
                        R$ {form.total.toFixed(2)}
                      </Text>
                    </Flex>
                  </Flex>
                  <Flex mb="1rem" direction="column">
                    <Text color="#2D3748" fontSize="0.750rem" fontWeight="500">
                      Chave Pix
                    </Text>
                    <Text color="#2D3748" fontSize="0.875rem" fontWeight="700">
                      {form.type}
                    </Text>
                    <Text color="#2D3748" fontSize="0.875rem" fontWeight="400">
                      {form.key}
                    </Text>
                  </Flex>
                </ModalBody>
                <ModalFooter gap="1rem" justifyContent="center">
                  <Button
                    onClick={onClose}
                    minWidth="120px"
                    size="md"
                    variant="outline"
                    bg="#fff"
                    color="#1A202C"
                    fontSize="0.875rem"
                    fontWeight="600"
                    border="1px solid #E2E8F0"
                    borderRadius="0.375rem"
                    _hover={{
                      bg: "#fff",
                      color: "#1A202C",
                      transition: "0.3s",
                    }}
                  >
                    Cancelar
                  </Button>
                  <Button
                    w="100%"
                    size="md"
                    variant="solid"
                    bg="#00335B"
                    color="#fff"
                    fontSize="0.875rem"
                    fontWeight="600"
                    border="1px solid #E2E8F0"
                    borderRadius="0.375rem"
                    _hover={{
                      bg: "#003f70",
                      color: "#fff",
                      transition: "0.3s",
                    }}
                    onClick={save}
                  >
                    Confirmar
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </Flex>
        </Flex>
      </Flex>
    </Layout>
  )
}
